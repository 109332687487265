import {Controller} from "stimulus";
import $ from "jquery";

export let ajaxErrorMessages = (jqXHR, textStatus, errorThrown) => {
    if (jqXHR.getResponseHeader('REQUIRES_AUTH') === '1') {
        alert('Su session expiró, por favor acceda nuevamente');
        window.location = jqXHR.getResponseHeader('ROOT_URL');
    }else{
        let message = "";
        if (jqXHR.status === 0) {
            message = 'Sin conexión: Verifique su conexión de red.';
        } else if(jqXHR.status == 400){
            message = ' Existen problemas en el request';
        } else if(jqXHR.status == 401){
            message = 'Authenticate nuevamente.';
        }else if (jqXHR.status == 403) {
            message = "Autentiquece nuevamente.";
        } else if (jqXHR.status == 404) {
            message = 'Página no encontrada [404]';
        } else if (jqXHR.status == 500) {
            message = 'Ha ocurrido un error [500] que fue notificado al equipo de soporte, intente nuevamente en unos minutos.';
        } else if (jqXHR.status == 503){
            message = "Por problemas ajenos al SER y Come2ec, no se ha podido obtener la reserva. Contacte con el administrador de la Red";
        } else if (textStatus === 'parsererror') {
            message = 'Ha ocurrido un error de interno [JSON] que fue notificado al equipo de soporte, intente nuevamente en unos minutos';
        } else if (textStatus === 'timeout') {
            message = 'Time out, tiempo de espera superado, inicie nuevamente, verifique su conexión de red.';
        } else if (textStatus === 'abort') {
            message = 'Request abortado.';
        } else {
            message = 'Error desconocido: ' + jqXHR.responseText;
        }
        alert(message);
    }
}

// type of message : 'success', 'info' ,'warning', 'error'
// message : message to show
// title : message title
export let toastr_message = (type, message, title = "") => {
    toastr.options.closeButton = true;
    toastr.options.progressBar = true;
    toastr.options.preventDuplicates = false;
    toastr.options.showDuration = 400;
    toastr.options.hideDuration = 1000;
    toastr.options.timeOut = 10000;
    toastr.options.extendedTimeOut = 2000;

    toastr.options.showEasing = 'swing';
    toastr.options.hideEasing = 'linear';
    toastr.options.closeEasing = 'linear';
    toastr.options.showMethod = 'fadeIn';
    toastr.options.hideMethod = 'fadeOut';
    toastr.options.positionClass = 'toast-bottom-right';
    toastr[type](message, title)

}

/**
 * FUNCIÓN QUE REDONDEA UN FLOAT CON LA PRESICIÓN DESPUÉS DE LA COMA
 * @param num
 * @param precision
 * @returns {string}
 */
export let myToFixed = (num, precision) => {
    return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
}

export default class extends Controller {
    connect() {
    }
}