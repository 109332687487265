import {Controller} from "stimulus";

require('block-ui/jquery.blockUI');
import $ from "jquery";
import {ajaxErrorMessages} from "./globalFunctions_controller";

export default class extends Controller {
    connect() {
    }

    /**
     * Ajax para mandar a crear un Job que permitirá exportar el reporte
     * */
    export_by_url(e){
        let url = this.data.get("url");
        fetch(url)
            .then(() => {
                $('.export').attr('disabled', 'disabled');
                setTimeout(function () {
                    $('.export').removeAttr('disabled');
                }, 40000);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

}
